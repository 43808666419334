import urlParams from './urlParams';

const isImgix = (url) => {
  if (!url) return false;
  return /imgix|pexels|images\.unsplash\.com/i.test(url);
};

const isCloudflare = (url) => {
  if (!url) return false;
  return /imagedelivery\.net/i.test(url);
};

const imageParams = (url, params) => {
  if (isImgix(url)) {
    return urlParams(
      url,
      {
        w: params.w,
        h: params.h,
        fit: params.fit
      }
    );
  }

  if (isCloudflare(url)) {
    const newUrl = urlParams(
      url.substring(0, url.lastIndexOf('/')),
      {
        width: params.w,
        height: params.h,
        fit: params.fit
      }
    );
    // HACK: CloudFlare's image parameter format is ridiculous, so we do _this_...
    return newUrl
      .replace('?', '/')
      .replaceAll('&', ',');
  }

  return url;
};

export default imageParams;
