import React from "react";
import Dropdown from "web-react-ui/src/components/modules/Dropdown";
import { Stack } from "@chakra-ui/react";
const stateFilterOptionsArray = [
  {
    key: "ALL",
    value: "all",
    text: "All States",
  },
  {
    key: "SCHEDULED",
    value: "scheduled",
    text: "Scheduled",
  },
  {
    key: "LIVE",
    value: "live",
    text: "Live",
  },
  {
    key: "EXPIRED",
    value: "expired",
    text: "Expired",
  },
  {
    key: "ERRORED",
    value: "error",
    text: "Errored",
  },
];

const contentTypeFilterOptionsArray = [
  {
    key: "ALL",
    value: "all",
    text: "All Types",
  },
  {
    key: "POSTS",
    value: "post",
    text: "Posts",
  },
  {
    key: "STORIES",
    value: "story",
    text: "Stories",
  },
];
interface PostsFiltersProps {
  callback: (filters: { stateGroup: string; contentType: string }) => void;
  currFilters: { stateGroup: string; contentType: string };
}
const PostsFilters = ({ callback, currFilters }: PostsFiltersProps) => {
  return (
    <Stack direction="row" className="posts-filters">
      <Dropdown
        className="status-filter"
        id="status"
        placeholder="All States"
        search
        fluid
        selection
        options={stateFilterOptionsArray}
        onChange={(
          _e_: React.ChangeEvent<HTMLInputElement>,
          { value }: { value: string }
        ) => callback({ ...currFilters, stateGroup: value })}
        value={currFilters.stateGroup}
        style={{ minWidth: "150px" }}
      />
      <Dropdown
        className="content-filter"
        id="contentType"
        placeholder="All Types"
        search
        fluid
        selection
        options={contentTypeFilterOptionsArray}
        onChange={(
          _e_: React.ChangeEvent<HTMLInputElement>,
          { value }: { value: string }
        ) => callback({ ...currFilters, contentType: value })}
        value={currFilters.contentType}
        style={{ minWidth: "150px" }}
      />
    </Stack>
  );
};
export default PostsFilters;
