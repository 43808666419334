import React from 'react';
import PropTypes from 'prop-types';

import { useI18Next } from 'shared/src/components/contexts/I18NextContext';

import Dropdown from '../modules/Dropdown';

const TimeRangeSelector = ({ handleSetRange, days, options }) => {
  const { strings } = useI18Next();

  const fallbackOptions = [
    {
      key: '7D',
      value: 7,
      text: strings('ui.component.timeRangeSelector.7D')
    },
    {
      key: '30D',
      value: 30,
      text: strings('ui.component.timeRangeSelector.30D')
    },
    {
      key: '60D',
      value: 60,
      text: strings('ui.component.timeRangeSelector.60D')
    },
    {
      key: '90D',
      value: 90,
      text: strings('ui.component.timeRangeSelector.90D')
    },
    {
      key: 'AllTimeD',
      value: Infinity,
      text: strings('ui.component.timeRangeSelector.364D')
    }
  ];

  return (
    <div>
      <Dropdown
        className='mr1'
        placeholder='Time Range'
        search
        fluid
        selection
        options={options || fallbackOptions}
        onChange={(event, { value }) => handleSetRange({ days: value })}
        value={days}
      />
    </div>
  );
};

TimeRangeSelector.propTypes = {
  handleSetRange: PropTypes.func,
  days: PropTypes.number
};

export default TimeRangeSelector;
