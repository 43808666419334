import {
  Flex,
  HStack,
  Skeleton,
  SkeletonCircle,
  SkeletonText,
} from '@chakra-ui/react';
import React from 'react';
import StatusBadge from 'shared/src/components/StatusBadge';

const SkeletonSocialPostCard = () => {
  return (
    <Flex
      as="article"
      borderWidth="1px"
      rounded="lg"
      minW="23rem"
      maxW="30rem"
      w="100%"
      h="100%"
      placeContent="start"
      direction="column"
      minHeight="34rem"
      border="xl"
      px="4"
      pb="8"
      gap="0.5rem"
      boxShadow="sm"
      className="_SkeletonCard"
      opacity={0.7}
    >
      <HStack className="_postItem-header" display="flex" w="100%" py="2">
        <SkeletonCircle size="10" />
        <Skeleton height="16px" flexGrow={1}></Skeleton>
      </HStack>
      <Skeleton height="320px"></Skeleton>
      <HStack
        py="4"
        overflow="hidden"
        display="flex"
        w="100%"
        justifyContent="space-between"
      >
        <Skeleton>
          <StatusBadge status={'loading'} />
        </Skeleton>
        <Skeleton h="10px" w="80px" />
      </HStack>
      <SkeletonText mt="2" noOfLines={4} spacing="4" />
    </Flex>
  );
};

export default SkeletonSocialPostCard;
