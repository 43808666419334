import {
  chakra,
  Flex,
  FormControl,
  FormLabel,
  Text,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import _ from 'lodash';
import useClient from '../../hooks/useClient';
import Profile from '../../interfaces/Profile.interface';
import SettingColor from './SettingColor';
import SettingSelect from './SettingSelect';

const BrandSettings = ({ profile, businessId }: {
  profile: Profile,
  businessId: string,
}) => {
  const client = useClient();

  const onChange = useMemo(
    () => async ({ name, value }: { name: string, value: any }) => {
      const body = {};
      _.set(body, name, value);

      await client
        .businesses.for(businessId)
        .social.modifyConfig(body);
    },
    [],
  );

  const brandStyleOptions = profile.brandStyles.map(style => ({
    label: style.name,
    value: style.id,
    disabled: style.restricted,
  }));

  return (
    <Flex direction="column" gap="1em" position="relative">
      <FormControl display="flex">
        <FormLabel fontSize="lg" w="50%">Primary Brand Color</FormLabel>
        <SettingColor
          name="branding.colors.primary"
          value={profile.config.branding.colors.primary}
          onChange={onChange}
        />
      </FormControl>
      <FormControl display="flex">
        <FormLabel fontSize="lg" w="50%">
          Brand Style
          <Text fontSize="small">
            See <chakra.a
            href="https://support.getintheloop.ca/knowledge/can-i-see-some-social-post-examples"
            target="_blank"
            rel="noreferrer"
            color="blue.500"
          >
            brand style examples
          </chakra.a>
          </Text>
        </FormLabel>
        <SettingSelect
          name="branding.style"
          value={profile.config.branding.style}
          onChange={onChange}
          options={brandStyleOptions}
        />
      </FormControl>
      <FormControl display="flex">
        <FormLabel fontSize="lg" w="50%">Brand Voice</FormLabel>
        <SettingSelect
          name="branding.voice"
          value={profile.config.branding.voice}
          onChange={onChange}
          options={[
            { label: 'Standard', value: 'standard' },
            { label: 'Adventurous', value: 'adventurous' },
            { label: 'Casual', value: 'casual' },
            { label: 'Innovative', value: 'innovative' },
            { label: 'Professional', value: 'professional' },
            { label: 'Sophisticated', value: 'sophisticated' },
            { label: 'Empowering', value: 'empowering' },
            { label: 'Warm-Inviting', value: 'warm-inviting' },
          ]}
        />
      </FormControl>
    </Flex>
  );
};

export default BrandSettings;
