import React, { useState } from "react";
import {
  Box,
  Flex,
  Skeleton,
} from '@chakra-ui/react';
import TimeRangeSelector from 'web-react-ui/src/components/metrics/TimeRangeSelector';
import PostStatsDisplay from 'shared/src/components/PostStatsDisplay';
import useRequest from 'shared/src/hooks/useRequest';
import WaitFor from 'web-react-ui/src/data/WaitFor';
import { useI18Next } from 'shared/src/components/contexts/I18NextContext';
import useClient from '../hooks/useClient';

const fetchStats = async ({
  businessId,
  days,
  client
}: {
  businessId: string;
  days: number;
  client: ReturnType<typeof useClient>;
}): Promise<PostStatsProps> => {
  // This lets us have a defined value for the TimeRangeSelector (Infinity)
  // while still not sending a `days` parameter.
  const actualDays = (days === Infinity) ? undefined : days;
  return client.businesses.for(businessId).social.getStats({ days: actualDays });
};
interface PostStatsProps {
  engagement: number | string;
  impressions: number | string;
}
const PostStats = ({ businessId, ...styles}: { businessId: string } & Record<string, string>) => {
  const { strings } = useI18Next();
  const client = useClient();
  const [days, setDays] = useState<number>(Infinity);
  const requestPostStats = useRequest(fetchStats, { days, businessId, client });
  const timeOptions = [
    {
      key: '30D',
      value: 30,
      text: strings('ui.component.timeRangeSelector.30D')
    },
    {
      key: '60D',
      value: 60,
      text: strings('ui.component.timeRangeSelector.60D')
    },
    {
      key: '90D',
      value: 90,
      text: strings('ui.component.timeRangeSelector.90D')
    },
    {
      key: 'AllTimeD',
      value: Infinity,
      text: strings('ui.component.timeRangeSelector.364D')
    }
  ];
  return (
    <Box {...styles}>
      <Flex gap="2" justify="end" pt="2" pb="4">
        <Skeleton
          startColor="gray.50"
          endColor="gray.200"
          isLoaded={!requestPostStats.loading}
        >
          <TimeRangeSelector
            days={days}
            options={timeOptions}
            handleSetRange={({ days: newDays }: { days: number }) =>
              setDays(newDays)
            }
          />
        </Skeleton>
      </Flex>
      <Box position="relative" className="box">
        <WaitFor
          waitFor={!requestPostStats.loading}
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)",
          }}
        />
        <Skeleton
          startColor="gray.50"
          endColor="gray.200"
          isLoaded={!requestPostStats.loading}
        >
          <PostStatsDisplay
            impressions={requestPostStats.result?.impressions}
            engagement={requestPostStats.result?.engagement}
          />
        </Skeleton>
      </Box>
    </Box>
  );
};

export default PostStats;
