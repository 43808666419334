import React from "react";
import { extendTheme, ChakraProvider,
  Box,
  Divider,
  Stat,
  StatLabel,
  StatNumber,
  StatGroup,
  Tooltip,
  Stack,
} from "@chakra-ui/react";
import { QuestionOutlineIcon } from "@chakra-ui/icons";

const theme = extendTheme({
  components: {
    Stat: {
      parts: ["container", "label", "number", "helpText", "icon"],
      baseStyle: {
        container: {
          borderRadius: "md",
          m: 4,
        },
        label: {
          fontWeight: "semibold",
          color: "gray.600",
        },
        number: {
          fontSize: "3rem !important",
          fontWeight: "semibold",
          color: "gray.900",
        },
        helpText: {
          fontSize: "md",
          color: "gray.500",
        },
      },
      sizes: {
        md: {
          number: {
            fontSize: "2.5rem !important",
          },
          label: {
            fontSize: "1rem !important",
          },
        },
      },
      variants: {},
      defaultProps: {},
    },
  },
});

interface PostStatsProps {
  engagement: number | string;
  impressions: number | string;
}

const PostStatsDisplay = ({ engagement, impressions }: PostStatsProps ) => {
  return (
    <Box w="100%">
      <StatGroup
        height="8rem"
        flexDirection="row-reverse"
        style={{ border: "1px solid #e4e4e7", borderRadius: "0.5rem" }}
      >
        <ChakraProvider theme={theme}>
          <Stat>
            <Stack align="center" direction="row" spacing={2}>
              <StatLabel>Engagement</StatLabel>
              <Tooltip
                hasArrow
                arrowSize={15}
                openDelay={400}
                label="The number of times users interacted with the post"
              >
                <QuestionOutlineIcon color="gray.400" />
              </Tooltip>
            </Stack>
            <StatNumber>{isNaN(Number(engagement)) ? '-' : engagement}</StatNumber>
          </Stat>
          <Divider
            orientation="vertical"
            style={{ height: "80%", margin: "auto" }}
          />
          <Stat>
            <Stack align="center" direction="row" spacing={2}>
              <StatLabel>Impressions</StatLabel>
              <Tooltip
                hasArrow
                arrowSize={15}
                openDelay={400}
                label="The number of times the post was displayed"
              >
                <QuestionOutlineIcon color="gray.400" />
              </Tooltip>
            </Stack>
            <StatNumber>{isNaN(Number(impressions)) ? '-' : impressions}</StatNumber>
          </Stat>
        </ChakraProvider>
      </StatGroup>
    </Box>
  );
};

export default PostStatsDisplay;
