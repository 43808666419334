import React, { useState } from 'react';
import { RootStateOrAny, useSelector } from 'react-redux';
import {
  Box, Flex, Heading, InputGroup, Input, InputLeftElement,
  Stack, useToast, Button, useDisclosure
} from '@chakra-ui/react';
import { AddIcon, SearchIcon } from '@chakra-ui/icons';
import { useDebounce } from 'use-debounce';

import authModule from 'shared/src/modules/auth';
import { useQuerystringParam } from 'shared/src/hooks/useQuerystringParam';
import useRequest from 'shared/src/hooks/useRequest';

import AddUserView from 'web-react-ui/src/chakra/users/AddUserView';
import UserListView from 'web-react-ui/src/chakra/users/UserListView';
import RoleFilter from 'web-react-ui/src/chakra/UserList/RoleFilter';
import User from 'web-react-ui/src/types/User.interface';

import client from '../../services/client';
import confirm from '../../services/confirm';

const AdminUsers = (): JSX.Element => {
  const currentUser = useSelector((state: RootStateOrAny) => authModule.selectors.getUser(state));
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [refetchId, setRefetchId] = useState(0);
  const [isInviting, setIsInviting] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [operationError, setOperationError] = useState<string>();
  const [query, setQuery] = useQuerystringParam('query', '', { squash: true });
  const [debouncedQuery] = useDebounce(query, 750);
  const [selectedRoles, setSelectedRoles] = useQuerystringParam(
    'roles',
    '',
    {
      transport: {
        serialize: (v: []) => v && v.join(','),
        parse: (v: string) => v && v.split(',')
      },
      squash: true
    });
  const toast = useToast();

  const loadUsers = () => {
    const args = {
      query: debouncedQuery || undefined,
      roleIds: selectedRoles || undefined,
    };
    return client.users.searchUsers(args);
  };

  const roles = useRequest(() => client.roles.list({ type: 'platform' }), {});

  const addUserSubmit = async (values: Record<string, any>) => {
    setIsInviting(true);
    setOperationError(undefined);
    const { email, name, roles: addRoles = [] } = values;

    if (!addRoles?.length) {
      setIsInviting(false);
      setOperationError('No roles selected');
      return;
    }

    try {
      await client.users.invite(
        { email, name },
        addRoles.map((roleId: string) => ({ id: roleId })),
        true
      );
      toast({
        title: 'User invited',
        description: `An invitation has been sent to ${email}.`,
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
      onClose();
      setRefetchId(refetchId + 1);
    } catch (error: any) {
      setOperationError(error);
      throw new Error(error);
    } finally {
      setIsInviting(false);
    }
  };

  const removeUser = async (user: User) => {
    const shouldDelete = confirm(
      /* eslint-disable-next-line max-len */
      `Are you sure you want to delete ${user.email}? Deleting this user will also remove all property, business, and location roles.`
    );
    if (!shouldDelete) return null;
    setIsDeleting(true);
    setOperationError(undefined);
    let timer: NodeJS.Timer;
    try {
      await client.users.for(user.id).delete();
      timer = setInterval(async () => {
        let count = 0;
        const userExists = await client.users.for(user.id).status();
        if (userExists.status === 'not_found') {
          setIsDeleting(false);
          clearInterval(timer);
          setRefetchId(refetchId + 1);
          return true;
        }
        // if we go ten rounds and still nothing, forget it.
        if (count > 10) {
          clearTimeout(timer);
          setRefetchId(refetchId + 1);
          toast({
            title: 'User is scheduled for deletion, but has not yet completed.',
            status: 'warning',
            duration: 5000,
            isClosable: true,
            position: 'top-right',
          });
          return true;
        }
        count += 1;
        return false;
      }, 1000);
    } catch (err: any) {
      setOperationError(err);
    }

    return false;
  };

  return (
    <Box maxW='7xl' mx='auto' px={[2, 0]}>
      <AddUserView
        isOpen={isOpen}
        onClose={onClose}
        type='platform'
        roleSectionHeading='Property Roles'
        primaryRoles={roles.result?.items}
        onSubmit={addUserSubmit}
        isLoading={isInviting}
        error={operationError}
      />
      <Flex
        direction={['column', 'row']}
        justify={['flex-start', 'space-between']}
        align={['flex-start', 'center']}
        my={10}
      >
        <Heading mb={[3, 3, 0]} minW='50%'>Users</Heading>
        <Stack direction={['column', 'row']} align='center' justify='flex-end' spacing={5} w='100%'>
          <InputGroup flexGrow={1}>
            <InputLeftElement color='gray.300'>
              <SearchIcon />
            </InputLeftElement>
            <Input
              value={query}
              onChange={(e: any) => setQuery(e.target.value)}
              placeholder='Search'
            />
          </InputGroup>
          <RoleFilter
            key={selectedRoles}
            roles={roles.result?.items}
            selectedRoles={selectedRoles}
            onSubmit={setSelectedRoles}
          />
          <Button colorScheme='blue' onClick={onOpen} leftIcon={<AddIcon />} minW='100px'>Add User</Button>
        </Stack>
      </Flex>

      <UserListView
        loadUsers={loadUsers}
        removeUser={removeUser}
        isDeleting={isDeleting}
        query={debouncedQuery}
        refetchId={refetchId}
        selectedRoles={selectedRoles}
        currentUser={currentUser}
        operationError={operationError}
        roles={roles.result?.items}
        baseUrl='/users'
        context='platform'
      />
    </Box>
  );
};

export default AdminUsers;
