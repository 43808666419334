import React, { useState } from 'react';
import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Box,
  Flex,
  Link,
  Skeleton,
} from '@chakra-ui/react';
import PostStatsDisplay from 'shared/src/components/PostStatsDisplay';
import useRequest from 'shared/src/hooks/useRequest';
import useClient from '../hooks/useClient';
import Post from '../interfaces/Post.interface';

const fetchStats = async ({
  businessId,
  postId,
  client,
}: {
  businessId: string;
  postId?: string;
  client: ReturnType<typeof useClient>;
}): Promise<PostStatsProps> => {
  if (!postId) return null;
  return client.businesses.for(businessId).social.getStats({ postId });
};

interface PostStatsProps {
  engagement: number | string;
  impressions: number | string;
}

const PostStatsSingle = ({ businessId, post, ...styles }: {
  businessId: string,
  post: Post
}) => {
  const client = useClient();
  const requestPostStats = useRequest<PostStatsProps>(fetchStats, { postId: post?.id, businessId, client });
  const shouldDisplayStats =
    post?.state === 'live' ||
    requestPostStats.result?.engagement > 0 ||
    requestPostStats.result?.impressions > 0;

  if (!shouldDisplayStats) return null;

  return (
    <Box {...styles}>
      <Box position="relative" className="box">
        <Skeleton
          startColor="gray.50"
          endColor="gray.200"
          isLoaded={!requestPostStats.loading}
        >
          <DisplayContent post={post} stats={requestPostStats.result} />
        </Skeleton>
      </Box>
    </Box>
  );
};

const FacebookStoryAlert = ({ post }: { post: Post }) => {
  return (
    <Alert status="info">
      <AlertIcon />
      <Flex direction="column">
        <AlertTitle>See Engagement Stats on Facebook</AlertTitle>
        <AlertDescription>
          Facebook does not currently provide engagement stats for stories to 3rd party apps.
          To see the impressions &amp; engagements for this story, please
          {' '}<Link href={post.socialNetworkUrl} target="_blank" rel="noreferrer" color="blue.500">
          View On Facebook
        </Link>.
        </AlertDescription>
      </Flex>
    </Alert>
  );
};

const InstagramStoryAlert = ({ post }: { post: Post }) => {
  return (
    <Alert status="info">
      <AlertIcon />
      <Flex direction="column">
        <AlertTitle>See Engagement Stats on Instagram</AlertTitle>
        <AlertDescription>
          Facebook does not provide engagement stats for stories to 3rd party apps
          until an engagement threshold is reached.
          To see the impressions &amp; engagements for this story, please
          {' '}<Link href={post.socialNetworkUrl} target="_blank" rel="noreferrer" color="blue.500">
          View On Instagram
        </Link>.
        </AlertDescription>
      </Flex>
    </Alert>
  );
};

const DisplayContent = ({ post, stats }: { post: Post, stats: PostStatsProps }) => {
  if (post.socialNetwork === 'facebook' && post.contentType === 'story') {
    return <FacebookStoryAlert post={post} />;
  }

  if (post.socialNetwork === 'instagram' && post.contentType === 'story') {
    return <InstagramStoryAlert post={post} />;
  }

  return <PostStatsDisplay impressions={stats?.impressions} engagement={stats?.engagement} />;
};

export default PostStatsSingle;
