import { Badge, Divider, Heading, Skeleton } from '@chakra-ui/react';
import React, { useMemo } from 'react';
import PostStats from 'shared/src/components/PostStats';
import useRequest from 'shared/src/hooks/useRequest';
import ProfileHealth from 'shared/src/components/ProfileHealth';
import SocialAccounts from 'shared/src/components/SocialAccounts';
import PageHeader from 'web-react-ui/src/chakra/page/PageHeader';
import PageLayout from 'web-react-ui/src/chakra/page/PageLayout';
import ActionMenu from 'web-react-ui/src/components/actionMenu/ActionMenu';
import Business from 'web-react-ui/src/types/Business.interface';
import BrandSettings from 'shared/src/components/social/BrandSettings';
import ContentSettings from 'shared/src/components/social/ContentSettings';
import GeneralSettings from 'shared/src/components/social/GeneralSettings';
import Profile from 'shared/src/interfaces/Profile.interface';
import client from '../../../../services/client';
import confirm from '../../../../services/confirm';
import SocialPostsList from './SocialPostsList';
import SocialCampaignsList from './SocialCampaignsList';

const disableProfile = async (business: Business) => {
  if (
    !confirm(
      [
        `Disable Social AI for ${business.name}?`,
        '\n\n',
        'All links to social media accounts will be removed and all stats will stop syncing. ',
        'Any scheduled posts will not be posted.',
        '\n\n',
        'This cannot be undone.',
      ].join('')
    )
  ) {
    return null;
  }

  return client.properties
    .for(process.env.REACT_APP_DEFAULT_PROPERTY)
    .businesses.for(business.id)
    .social.updateProfile({ enabled: false });
};

const fetchProfile = async ({
  businessId,
}: {
  businessId: string;
}): Promise<any> => {
  if (!businessId) return undefined;
  return client.businesses
    .for(businessId)
    .social.getProfile()
    .catch((err) => {
      if (err.status === 404) return {};
      throw err;
    });
};
const ProfileStatusBadge = ({ profile }: { profile?: Profile }) => {
  const props = {
    p: '0.75em',
    children: '',
    colorScheme: 'gray',
  };
  if (profile?.enabled === undefined) {
    return <Badge {...props}>Not Configured</Badge>;
  }
  if (profile.enabled === true) {
    props.children = 'Enabled';
    props.colorScheme = 'green';
  } else if (profile.enabled === false) {
    props.children = 'Disabled';
    props.colorScheme = 'red';
  }
  return <Badge {...props} />;
};

const ProfilePlanBadge = ({ profile }: { profile?: Profile }) => {
  if (!profile) return null;
  return (
    <Badge textTransform="uppercase" p="0.75em">
      {profile.plan}
    </Badge>
  );
};

const AdminSocialSection = ({ business }: { business: Business }) => {
  const disableRequest = useRequest(disableProfile);
  const profileRequest = useRequest(fetchProfile, {
    businessId: business.id,
    counter: disableRequest.counter,
  });
  const actions = useMemo(
    () => [
      {
        label: 'Disable Profile',
        negative: true,
        action: async () => {
          disableRequest.run(business);
        },
        extraProps: {
          isLoading: disableRequest.loading,
        },
        hidden: !profileRequest.result?.enabled,
      },
    ],
    [business, disableRequest, profileRequest.result]
  );

  return (
    <PageLayout>
      <PageHeader
        controls={<ActionMenu actions={actions} suppressPrimary />}
        pb="0"
      >
        Social AI
        <Skeleton
          display="inline-block"
          isLoaded={profileRequest.settled}
          mx="1em"
        >
          <ProfileStatusBadge profile={profileRequest.result} />
          <ProfilePlanBadge profile={profileRequest.result} />
        </Skeleton>
      </PageHeader>
      <PostStats businessId={business.id} />
      <ProfileHealth
        profile={profileRequest.result}
        context="admin"
        businessId={business.id}
        hasLinks
      />
      {profileRequest.result?.enabled !== undefined ? (
        <>
          <SocialAccounts
            canEdit={false}
            business={business}
            profile={profileRequest.result}
          />
          <Heading size="lg">Brand</Heading>
          <BrandSettings
            profile={profileRequest.result}
            businessId={business.id}
          />
          <Heading size="lg">Content</Heading>
          <ContentSettings
            profile={profileRequest.result}
            businessId={business.id}
          />
          <Heading size="lg">General</Heading>
          <GeneralSettings
            profile={profileRequest.result}
            businessId={business.id}
          />
          <Divider />
          <Heading size="lg">Campaigns</Heading>
          <SocialCampaignsList business={business} />
          <Divider />
          <SocialPostsList business={business} />
        </>
      ) : null}
    </PageLayout>
  );
};

export default AdminSocialSection;
