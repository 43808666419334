import { Box } from '@chakra-ui/react';
import client from 'admin/src/services/client';
import React, {
  useCallback,
  useMemo,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';
import getPostPageTitle from 'shared/src/components/getPostPageTitle';
import PostEdit from 'shared/src/components/PostEdit';
import confirm from 'shared/src/helpers/confirm';
import useRequest from 'shared/src/hooks/useRequest';
import Post from 'shared/src/interfaces/Post.interface';
import SkeletonDetailView from './SkeletonDetailView';
import SocialPostPreview from './SocialPostPreview';

type paramsProps = {
  postId: string;
  businessId: string;
};

const fetchPost = async ({
  businessId,
  postId,
}: {
  businessId: string;
  postId: string;
}): Promise<Post> => {
  return client.businesses.for(businessId).social.posts.for(postId).details();
};

const fetchStats = async ({
  businessId,
  postId
}: {
  businessId: string;
  postId: string;
}): Promise<PostStatsProps> => {
  return client.businesses.for(businessId).social.getStats({ postId });
};

const deletePost = async ({
  businessId,
  postId,
}: {
  businessId: string;
  postId: string;
}): Promise<void> => {
  return client.businesses.for(businessId).social.posts.for(postId).delete();
};
interface PostStatsProps {
  engagement: number | string;
  impressions: number | string;
}

const SocialPostDetails = ({ businessId, postId }: paramsProps) => {
  const property = process.env.REACT_APP_DEFAULT_PROPERTY;
  const history = useHistory();
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const postRequest = useRequest(fetchPost, {
    propertyId: property,
    businessId,
    postId,
  });
  const post = postRequest.result;
  const canEdit = post?.state === 'scheduled' && post?.contentType !== 'story';
  const canDelete = post?.state === 'scheduled';
  const requestPostAndStats = useRequest(fetchStats, { businessId, postId });
  const stats: PostStatsProps = requestPostAndStats.result;
  const onSave = useCallback(
    async msg => {
      function isMessageValid(userInput: string) {
        // to be improved, we could add type checks here
        return userInput.length >= 10;
      }

      if (isMessageValid(msg)) {
        const data = await client.businesses
          .for(businessId)
          .social.posts.for(postId)
          .update({
            message: msg,
          });
        setIsEditing(false);
        setIsInvalid(false);
        if (typeof window !== 'undefined') {
          // Refetch the post
          // FIXME: Find better way to do this.
          // Just re-running postRequest is too fast =\ so we get the pre-edit indexed version
          window.location.reload();
        }
        return;
      }
      setIsInvalid(true);
    },
    [history, client, businessId, postId],
  );

  const actions = useMemo(
    () => [
      {
        label: 'Edit',
        hidden: !canEdit || isEditing,
        action: async () => {
          // to be modified
          setIsEditing(s => !s);
        },
      },
      {
        label: 'Close',
        hidden: !isEditing,
        action: async () => {
          // to be modified
          setIsEditing(s => !s);
        },
      },
      {
        label: 'Delete',
        hidden: !canDelete,
        action: async () => {
          const doDelete = confirm(
            'Delete Post\n\nYou cannot undo this action.',
          );
          if (!doDelete) return;

          await deletePost({ businessId, postId });

          if (typeof window !== 'undefined') {
            const qsp = new URLSearchParams(window.location.search);
            qsp.delete('businessId');
            qsp.delete('postId');
            const target = `/social/posts?${qsp.toString()}`;

            history.replace(target);
            // Refresh the posts list
            // FIXME: Find better way to do this
            window.location.reload();
          }
        },
      },
    ],
    [postId, post, canEdit, canDelete, isEditing],
  );

  const pageTitle = getPostPageTitle(post);

  return (
    <Box
      w={{ base: '100%', xl: '70vw' }}
      maxW="1440px"
      className="_socialPostDetails"
      fontSize="16px"
      color="#4267B2"
    >
      {!post && <SkeletonDetailView />}
      {post && isEditing && (
        <PostEdit
          post={{ ...post, pageTitle }}
          onSave={onSave}
          isInvalid={isInvalid}
          actions={actions}
        />
      )}
      {post && !isEditing && (
        <SocialPostPreview post={{ ...post, pageTitle }} businessId={businessId} actions={actions} />
      )}
    </Box>
  );
};

export default SocialPostDetails;
