import {
  Flex,
  FormControl,
  FormLabel,
} from '@chakra-ui/react';
import React, {
  useMemo,
} from 'react';
import _ from 'lodash';
import useClient from '../../hooks/useClient';
import Profile from '../../interfaces/Profile.interface';
import SettingSelect from './SettingSelect';

const SETTINGS = [
  {
    label: 'Message Length',
    key: 'content.messageLength',
    options: [
      {
        label: 'Concise',
        value: 'concise',
      },
      {
        label: 'Normal',
        value: 'normal',
      },
      {
        label: 'Verbose',
        value: 'verbose',
      },
    ],
  },
  {
    label: 'Hashtags',
    key: 'content.hashtags',
    options: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Some',
        value: 'some',
      },
      {
        label: 'Lots',
        value: 'many',
      },
    ],
  },
  {
    label: 'Emoji Use',
    key: 'content.emojiUse',
    options: [
      {
        label: 'None',
        value: 'none',
      },
      {
        label: 'Some',
        value: 'light',
      },
      {
        label: '👍💪😎❤️',
        value: 'heavy',
      },
    ],
  },
  {
    label: 'Post Frequency',
    note: 'Admin Only',
    key: 'postFrequency',
    options: [
      {
        label: 'Infrequent',
        value: 'light',
      },
      {
        label: 'Standard',
        value: 'moderate',
      },
      {
        label: 'Frequent',
        value: 'heavy',
      },
    ],
  },
];

const ContentSettings = ({ profile, businessId, hidePostFrequency }: {
  profile: Profile,
  businessId: string,
  hidePostFrequency?: boolean,
}) => {
  const values = profile?.config ?? {};

  const client = useClient();

  const onChange = useMemo(
    () => async ({ name, value }: { name: string, value: any }) => {
      const body = {};
      _.set(body, name, value);

      await client
        .businesses.for(businessId)
        .social.modifyConfig(body);
    },
    [],
  );

  return (
    <Flex direction="column" gap="1em" position="relative">
      {
        SETTINGS.map(setting => {
          if (hidePostFrequency && setting.key === 'postFrequency') return null;
          return (
            <FormControl key={setting.key} display="flex">
              <FormLabel fontSize="lg" w="50%">
                {setting.label}
                {setting.note && <p><small>{setting.note}</small></p>}
              </FormLabel>
              <SettingSelect
                name={setting.key}
                value={_.get(values, setting.key)}
                onChange={onChange}
                options={setting.options}
              />
            </FormControl>
          );
        })
      }
    </Flex>
  );
};

export default ContentSettings;
